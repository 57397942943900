:root {
  --bg: #111111;
  --dropdown-bg: #161616;
  --bg-accent: #222;
  --text-color: #fefefe;
  --nav-size: 60px;
  --border: 2px solid #444;
  --border-radius: 4px;
  --speed: 500ms;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}
